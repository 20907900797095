import http, { resultDecrypt } from ".";
import settings from "../config/settings";

const USERS_API = 'https://users.destralegal.ru';

export const writeUsersData = (userId, userData) =>
  http
    .post(`${USERS_API}/api/v1/byUserId/${userId}`, userData, {
      headers: { "X-Destra-Authorization": `Bearer ${settings.access_token}` }
    })
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data, () => writeUsersData(userId, userData)))
    .catch((err) => console.log(err));

export const updateUsersData = (access_token, userData) =>
  http
    .put(`${USERS_API}/api/v1/update`, userData, {
      headers: { "X-Destra-Authorization": `Bearer ${access_token}` }
    })
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => {
      console.log(err);
      // resultDecrypt(err.response.data, () => updateUsersData(userData))
    })
    .catch((err) => console.log(err));

export const updateUsersPhone = (access_token, phone) =>
  http
    .put(
      `${USERS_API}/api/v1/addPhone`,
      { phone },
      {
        headers: { "X-Destra-Authorization": `Bearer ${access_token}` }
      }
    )
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => {
      console.log(err);
      // resultDecrypt(err.response.data, () => updateUsersData(userData))
    })
    .catch((err) => console.log(err));
