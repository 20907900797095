import http, { resultDecrypt } from "./index";

const ORDER_API = 'https://backend.web.apps.destralegal.ru';

export const createOrder = (access_token, orderData) =>
  http
    .post(`${ORDER_API}/api/v1/orders`, orderData, {
      headers: { "x-destra-authorization": `Bearer ${access_token}` }
    })
    .then(({ data }) => data.result)
    .catch((err) => console.log(err));
